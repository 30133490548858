import React, { useEffect, useState } from "react";
import "./Formnavcss.css";
import { Button, ButtonGroup } from "@material-ui/core";
import axios from "../../axios";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

function FormNavbar() {
  const history = useHistory();
  const [data, setData] = useState([]);

  const { id } = useParams();
  console.log("id: ", id);

  function back() {
    return history.go(-1);
  }

  useEffect(() => {
    async function FetchData() {
      console.log("inside");
      const req = await axios.post("/data", { user: id });
      console.log("hehehehe", id, req.data);
      setData(req.data);
    }

    FetchData();
  }, []);
  return (
    <div className="formHeader">
      <img src={`${data.path}`} className="logo" />
      <div className="return">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            back();
          }}
        >
          Return Back
        </Button>
      </div>
    </div>
  );
}

export default FormNavbar;
