import React from 'react'
import './Formfooter.css'

function formFooter() {
    return (
        <div className="footer">
            <div className="data">
                <h4>Powered by: </h4><img className="logof" src="https://techbud.in/static/media/white_techbud_final_small2.2a588707.png"/>
            </div>
        </div>
    )
}

export default formFooter
