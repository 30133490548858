import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import axios from "../axios";
import { useHistory } from "react-router-dom";

function GenerateCertificate(props) {
  let history = useHistory();
  return (
    <div>
      <Button
        onClick={() => {
          console.log("history", history);
          history.replace(
            `/certificate?id=${props.data.receiptNumber}&startdate=${props.data.startdate}&name=${props.data.a1}`
          );
        }}
      >
        Certi
      </Button>
    </div>
  );
}

export default GenerateCertificate;
