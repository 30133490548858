import { Button } from "@material-ui/core";
import React from "react";
import axios from "../axios";

function DeleteData({ receipt }) {
  const receiptNumber = receipt;

  function deleteData(receiptNumber) {
    const user = localStorage.getItem("user");
    axios
      .put("/deleteData", { user, receiptNumber })
      .then((res) => {
        console.log("resp", res.data);
        location.reload();
      })
      .catch((error) => console.log(error.response.data));
  }

  return (
    <div>
      <Button
        onClick={() => {
          deleteData(receiptNumber);
        }}
      >
        Delete
      </Button>
    </div>
  );
}

export default DeleteData;
