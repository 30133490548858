import axios from "axios";
import https from "https";

const instance = axios.create({
  baseURL: "https://memberbackend.techbud.in",
  // baseURL: "http://localhost:8001",
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

export default instance;
