/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import ActiveTableList from "views/TableList/ActiveTableList.js";
import Deleted from "../src/views/TableList/Deleted.js";
import InactiveTableList from "views/TableList/InactiveTableList.js";
import Login from "views/Login/Login.js";
import Form from "components/Form/formCode";
// core components/views for RTL layout
import { useState } from "react";
import Delete from "@material-ui/icons/Delete";

const token = localStorage.getItem("my_token");
const user = localStorage.getItem("user");
const email = localStorage.getItem("email");

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Member Overview",
    icon: Dashboard,
    component: user && token ? DashboardPage : Login,
    layout: "/admin",
  },
  {
    path: "/active-member",
    name: "Active Member",
    icon: "person",
    component: user && token ? ActiveTableList : Login,
    layout: "/admin",
  },
  {
    path: "/inactive-member",
    name: "Inactive Member",
    icon: "person",
    component: user && token ? InactiveTableList : Login,
    layout: "/admin",
  },
  {
    path: "/deleted",
    name: "Deleted Members",
    icon: Delete,
    component: user && token ? Deleted : Login,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Log In",
    icon: "",
    component: user && token ? DashboardPage : Login,
    invisible: true,
    layout: "",
  },
  {
    path: "/adduser",
    name: "Add User",
    icon: "",
    component: user && token ? DashboardPage : Login,
    invisible: true,
    layout: "",
  },
];

export default dashboardRoutes;
