import axios from "../axios";
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { Button } from "@material-ui/core";
import "reactjs-popup/dist/index.css";
function EditPopup({ receiptNumber, amount, status, start, end }) {
  console.log("uhm", start);
  var sdate = new Date(start).toISOString().substring(0, 10);
  var edate = new Date(end).toISOString().substring(0, 10);
  const [changeData, setChangeData] = useState({
    amount: parseInt(amount),
    status: status,
    st: sdate,
    ed: edate,
  });

  function UpdateNow(changeData, receiptNumber) {
    console.log(changeData);
    const user = localStorage.getItem("user");
    axios
      .put("/edit", { changeData, user, receiptNumber })
      .then((res) => {
        console.log("resp", res.data);
        location.reload();
      })
      .catch((error) => console.log(error.response.data));
  }

  function onSubmit() {
    UpdateNow(changeData, receiptNumber);
  }

  // var d = new Date(ed);
  // var s = new Date(st);
  // let sDateString =
  // s.getFullYear().toString() +
  // "-" +
  // (s.getMonth() + 1).toString().padStart(2, "0") +
  // "-" +
  // s.getDate().toString().padStart(2, "0");

  // let datestring =
  //   d.getFullYear().toString() +
  //   "-" +
  //   (d.getMonth() + 1).toString().padStart(2, "0") +
  //   "-" +
  //   d.getDate().toString().padStart(2, "0");
  // console.log("endDateF", datestring);

  const handleChange = (e) => {
    setChangeData({ ...changeData, [e.target.name]: e.target.value });
    console.log(changeData);
  };
  return (
    <Popup trigger={<Button> Edit</Button>} position="left center">
      <form onSubmit={onSubmit}>
        <label for="amount">Amount</label>
        <input
          type="number"
          id="amount"
          name="amount"
          value={changeData.amount}
          onChange={handleChange}
        />
        <label for="status">Status</label>
        <br></br>
        <select
          name="status"
          value={changeData.status}
          id="status"
          default
          onChange={handleChange}
        >
          <option value="Pending">Select Status</option>
          <option value="Success">Success</option>
          <option value="Pending">Pending</option>
        </select>
        <br></br>
        <label for="sdate">Start Date</label>
        <input
          type="date"
          id="startdate"
          name="st"
          value={changeData.st}
          onChange={handleChange}
        />
        <br></br>
        <label for="edate">End Date</label>
        <input
          type="date"
          id="enddate"
          name="ed"
          value={changeData.ed}
          onChange={handleChange}
        />
        <br></br>
        <br></br>
        <Button type="button" onClick={() => onSubmit()}>
          Submit
        </Button>
      </form>
    </Popup>
  );
}

export default EditPopup;
