import React from "react";
import { Link } from "react-router-dom";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import classNames from "classnames";
import toastifier from "toastifier";
import "toastifier/dist/toastifier.min.css";

// import { bugs, website, server } from "variables/general.js";

import axios from "../../axios";
import setAuthToken from "../../components/utils/setAuthToken";

import { dailySalesChart } from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.js";
import "variables/chartstyles.css";

const useStyles = makeStyles(styles);

const months = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

const oneday = 24 * 60 * 60 * 1000;

const timeOptions = [
  { value: oneday, label: "Last 24 Hours" },
  { value: 7 * oneday, label: "Last 7 Days" },
  { value: 14 * oneday, label: "Last 14 Days" },
  { value: 30 * oneday, label: "Last 30 Days" },
  { value: 3 * 30 * oneday, label: "Last 3 Months" },
  { value: 6 * 30 * oneday, label: "Last 6 Months" },
  { value: 1, label: "This month" },
  { value: 11, label: "This year" },
];

export default function Dashboard() {
  const [data, setData] = useState([]);
  const [contacts, setContacts] = useState([]);

  const classes = useStyles();

  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  function Total(data) {
    var total = 0;
    data.map((data1) =>
      data1.status === "Success" ? (total = total + data1.amount) : ""
    );
    //console.log(total);
    return total;
  }

  function TotalMember(data) {
    var total = 0;
    data.map((data1) =>
      data1.status === "Success" ? (total = total + 1) : ""
    );
    //console.log(total);
    return total;
  }

  function TotalMemberInactive(data) {
    var total1 = 0;
    data.map((data1) =>
      data1.status === "Pending" ? (total1 = total1 + 1) : ""
    );
    //console.log(total);
    return total1;
  }

  function TotalMemberInactiveAmount(data) {
    var total1 = 0;
    data.map((data1) =>
      data1.status === "Pending" ? (total1 = total1 + 1) : ""
    );
    //console.log(total);
    return total1;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("my_token");
      const api = localStorage.getItem("api");
      if (api != "none") {
        // const cont = await fetch(
        //   `https://campaigns.techbud.in/v2/contact/count?apikey=${api}`
        // );        
        // const final = await cont.json();
        // console.log("email data",final.data);
        // if (final.data === undefined){
          setContacts("Not Subscribed")
        // }else {
        //   setContacts(final.data);
        // }
      }
      setAuthToken(token);
      const req = await axios.get("/pay");
      setData(req.data);
      } catch (error) {
        console.log("error", error)
      }
      
      
    }

    
    fetchData();
    
  }, []);

  useEffect(()=>{
    setChartMonthly()
  },[data])

  const [labelData, setLabelData] = useState([]);
  const [chartData, setchartData] = useState([]);
  const [amountboxdata, setAmountboxdata] = useState();
  const [rangeSelected, setRangeselected] = useState(false);

  function setChartYearly() {
    setRangeselected(false);
    let newlabel = [];
    let newchartdata = [];
    let map = {};
    for (let i = 0; i < data.length; i++) {
      if (data[i].status === "Success") {
        let yeardata = data[i].startdate.slice(0, 4); //year
        if (map.hasOwnProperty(yeardata)) {
          //console.log(map.get(yeardata));
          map[yeardata] = map[yeardata] + data[i].amount;
        } else {
          map[yeardata] = data[i].amount;
        }
      }
    }
    for (const yeardata in map) {
      newlabel.push(yeardata);
      newchartdata.push(map[yeardata]);
    }
    //console.log(map);
    setLabelData(newlabel);
    setchartData(newchartdata);
  }

  function setChartMonthly() {
    console.log("called monthly")
    setRangeselected(false);
    let newlabel = [];
    let newchartdata = [];
    let map = {};
    for (let i = 0; i < data.length; i++) {
      if (data[i].status === "Success") {
        let monthdata =
          data[i].startdate.slice(0, 4) + data[i].startdate.slice(5, 7); //year+month
        if (map.hasOwnProperty(monthdata)) {
          //console.log(map.get(monthdata));
          map[monthdata] = map[monthdata] + data[i].amount;
        } else {
          map[monthdata] = data[i].amount;
        }
      }
    }
    for (const monthdata in map) {
      newlabel.push(
        months[monthdata.slice(4, 6)] + " " + monthdata.slice(0, 4)
      );
      newchartdata.push(map[monthdata]);
    }
    //console.log(map);
    setLabelData(newlabel);
    setchartData(newchartdata);
  }

  async function pingInactive() {
    const user = localStorage.getItem("user");
    const name = localStorage.getItem("name");
    console.log("user", user);
    console.log("pinged");
    toastifier("Pinged Inactive Members");
    const data = await axios.post("/pingInactive", { user: user, name: name });
    console.log("uhm", data);
  }

  const handleTimeRange = (timeDuration) => {
    setRangeselected(true);
    let newlabel = [];
    let newchartdata = [];
    let newboxdata = 0;
    let map = {};
    let timenow = new Date();
    if (timeDuration === 11) {
      for (let i = 0; i < data.length; i++) {
        let timethen = new Date(data[i].startdate);
        if (
          data[i].status === "Success" &&
          timenow >= timethen &&
          timethen.getUTCFullYear() === timenow.getUTCFullYear()
        ) {
          let daydata =
            data[i].startdate.slice(0, 4) +
            data[i].startdate.slice(5, 7) +
            data[i].startdate.slice(8, 10); //year+month+day
          if (map.hasOwnProperty(daydata)) {
            //console.log(map.get(daydata));
            map[daydata] = map[daydata] + data[i].amount;
          } else {
            map[daydata] = data[i].amount;
          }
          newboxdata += data[i].amount;
        }
      }
    } else if (timeDuration === 1) {
      for (let i = 0; i < data.length; i++) {
        let timethen = new Date(data[i].startdate);
        if (
          data[i].status === "Success" &&
          timenow >= timethen &&
          timethen.getUTCMonth() === timenow.getUTCMonth() &&
          timethen.getUTCFullYear() === timenow.getUTCFullYear()
        ) {
          let daydata =
            data[i].startdate.slice(0, 4) +
            data[i].startdate.slice(5, 7) +
            data[i].startdate.slice(8, 10); //year+month+day
          if (map.hasOwnProperty(daydata)) {
            //console.log(map.get(daydata));
            map[daydata] = map[daydata] + data[i].amount;
          } else {
            map[daydata] = data[i].amount;
          }
          newboxdata += data[i].amount;
        }
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        let timethen = new Date(data[i].startdate);
        if (
          data[i].status === "Success" &&
          timenow >= timethen &&
          Math.ceil(timenow - timethen) <= timeDuration
        ) {
          let daydata =
            data[i].startdate.slice(0, 4) +
            data[i].startdate.slice(5, 7) +
            data[i].startdate.slice(8, 10); //year+month+day
          if (map.hasOwnProperty(daydata)) {
            //console.log(map.get(daydata));
            map[daydata] = map[daydata] + data[i].amount;
          } else {
            map[daydata] = data[i].amount;
          }
          newboxdata += data[i].amount;
        }
      }
    }
    for (const daydata in map) {
      newlabel.push(
        daydata.slice(6, 8) +
        " " +
        months[daydata.slice(4, 6)] +
        " " +
        daydata.slice(0, 4)
      );
      newchartdata.push(map[daydata]);
    }
    //console.log(map);
    setLabelData(newlabel);
    setchartData(newchartdata);
    setAmountboxdata(newboxdata);
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={3} sm={3}>
          <Card>
            <CardHeader>
              <p className={classes.cardCategory}>Total Active Member</p>
              <h3 className={classes.cardTitle}>{TotalMember(data)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Link to="/admin/active-member">
                  <Button color="primary">View</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={3} sm={3}>
          <Card>
            <CardHeader>
              <p className={classes.cardCategory}>Total Inactive Member</p>
              <h3 className={classes.cardTitle}>{TotalMemberInactive(data)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Link to="/admin/inactive-member">
                  <Button color="primary">View</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={3} sm={3}>
          {localStorage.getItem("api") != "none" ? (
            <Card>
              <CardHeader>
                <p className={classes.cardCategory}>Total Email Contacts</p>
                <h3 className={classes.cardTitle}>{contacts}</h3>
              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                <a href="https://campaigns.techbud.in/login" target="_blank">
                  <Button color="primary">View</Button>
                </a>
              </div>
                <div className={classes.stats} style={{marginLeft: "1rem"}}>
                  Total contacts in our AI Powered Email Marketing
                  Platform <br/>
                </div>
              </CardFooter>
            </Card>
          ) : (
            <Card>
              <CardHeader>
                <p className={classes.cardCategory}>Total Payment Pending</p>
                <h3 className={classes.cardTitle}>
                  Rs {TotalMemberInactiveAmount(data)}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  Total amount inactive members have to pay
                </div>
              </CardFooter>
            </Card>
          )}
        </GridItem>
        <GridItem xs={3} sm={3}>
          <Card style={{ opacity: rangeSelected ? "1" : "0" }}>
            <CardHeader>
              <p className={classes.cardCategory}>Amount in selected range</p>
              <h3 className={classes.cardTitle}>
                <small>Rs </small>
                {amountboxdata}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <div>Total amount received in the selected range</div>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={3} sm={3}>
          <Button color="primary" onClick={setChartMonthly}>
            View Monthly Chart
          </Button>
        </GridItem>
        <GridItem xs={3} sm={3}>
          <Button color="primary" onClick={setChartYearly}>
            View Yearly Chart
          </Button>
        </GridItem>
        <GridItem xs={3} sm={3}>
          <Button color="primary" onClick={pingInactive}>
            Ping Inactive
          </Button>
        </GridItem>
        <GridItem xs={3} sm={3} style={{ zIndex: 10 }}>
          <Button color="primary" onClick={handleClickProfile}>
            Select Time Range
          </Button>
          <Poppers
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openProfile }) +
              " " +
              classes.popperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    <MenuList role="menu">
                      {timeOptions.map((option) => {
                        return (
                          <MenuItem
                            onClick={() => {
                              handleTimeRange(option.value);
                            }}
                            className={classes.dropdownItem}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader>
              <ChartistGraph
                className={"ct-chart"}
                data={{ labels: labelData, series: [chartData] }}
                type={"Line"}
                options={{ high: Math.max(chartData) }}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardFooter chart></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
