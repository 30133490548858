import React from "react";
import EditPopup from "./EditPopup";
import DeleteData from "./DeleteData";
import "./Ftable.css";

function FTable({ datas, loading }) {
  if (loading) {
    return <h2>Loading</h2>;
  }
  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="col">Membership No</th>
            <th className="col">Name</th>
            <th className="col">Email</th>
            <th className="col">Status</th>
            <th className="col">Amount</th>
            <th className="col">Phone</th>
            <th className="col">Company Name</th>
            <th className="col">Type of Business</th>
            <th className="col">Turnover of Company</th>
            <th className="col">Start Date (yyyy-mm-dd)</th>
            <th className="col">End Date (yyyy-mm-dd)</th>
            <th className="col">Edit Data</th>
          </tr>
        </thead>
        {datas.map(
          (data) =>
            data.status === "Pending" && (
              <>
                <tbody>
                  <tr>
                    <td key={data.receiptNumber} className="col">
                      {data.receiptNumber}
                    </td>
                    <td className="col">{data.buyer_name}</td>
                    <td className="col">
                      {data.email}
                      {console.log(data.email)}
                    </td>
                    <td className="col">{data.status}</td>
                    <td className="col">{data.amount}</td>
                    <td className="col">{data.phone}</td>
                    <td className="col">{data.a1}</td>
                    <td className="col">{data.a2}</td>
                    <td className="col">{data.a3}</td>
                    <td className="col">{data.startdate.slice(0, 10)}</td>
                    <td className="col">{data.enddate.slice(0, 10)}</td>
                    <td className="col">
                      <EditPopup
                        receiptNumber={data.receiptNumber}
                        amount={data.amount}
                        status={data.status}
                        start={data.startdate}
                        end={data.enddate}
                      />
                      <DeleteData receipt={data.receiptNumber} />
                    </td>
                  </tr>
                </tbody>
              </>
            )
        )}
      </table>
    </div>
  );
}

export default FTable;
