import React, { useState, useEffect } from "react";
import axios from "../../axios";
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Radio,
  RadioGroup,
  Checkbox,
  Select,
} from "@material-ui/core";
import FormNavbar from "./formNavbar";
import "./formCode.css";
import FormFooter from "./formFooter";
import { useParams } from "react-router";

const initialState = { email: "", password: "" };

function Form() {
  let { id, amount } = useParams();
  console.log("id and amount", id, amount);
  const [formData, setFormData] = useState(initialState);
  const [formq, setFormq] = useState([]);

  useEffect(() => {
    async function FetchData() {
      console.log("inside");
      const req = await axios.post("/data", { user: id });
      console.log("hehehehe", id, req.data);
      setFormq(req.data);
    }

    FetchData();
  }, []);

  function buyNow(formData) {
    // console.log("clicked");
    const data = {
      ...formData,
      purpose: `${formData.a2}`,
      amount: `${amount}`,
      from: `${formq.Name}`,
      redirect_url: `https://api.techbud.in/callback?user_id=${id}`,
    };
    axios
      .post(`/pay/${id}`, data)
      .then((res) => {
        console.log("resp", res.data);
        window.location.href = res.data;
      })
      .catch((error) => console.log(error.response.data));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // axios.post('./pay/6085bd330fd10a1fa00fc86f', formData)
    buyNow(formData);
  };

  const handleChange = (e) => {
    console.log("hi");
    setFormData({ ...formData, [e.target.name]: e.target.value });
    //console.log(formData);
  };

  const displayDropdown = (num) => {
    const options = formq["dropdown" + num];
    if (options.length > 0) {
      console.log("yes dropdown for a" + num);
      return (
        <Select
          name={"a" + num}
          placeholder="Select option"
          className="input"
          onChange={handleChange}
        >
          {options.map((currentoption) => {
            return <option value={currentoption}>{currentoption}</option>;
          })}
        </Select>
      );
    } else {
      console.log("no dropdown for a" + num);
      return (
        <Input
          required
          type="text"
          name={"a" + num}
          className="input"
          onChange={handleChange}
        />
      );
    }
  };

  return (
    <div style={{ color: "white" }}>
      <FormNavbar />
      <div>
        <form onSubmit={handleSubmit} className="form">
          <div className="forml">
            <FormControl id="email" required>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                name="buyer"
                className="input"
                onChange={handleChange}
              />
              <FormHelperText>
                Please enter your email, personal or work both can be used.
              </FormHelperText>
            </FormControl>
            <FormControl id="name" required>
              <FormLabel>Full Name</FormLabel>
              <Input
                type="text"
                className="input"
                name="buyer_name"
                onChange={handleChange}
              />
              <FormHelperText>Please enter your full name.</FormHelperText>
            </FormControl>
            <FormControl id="phone" required>
              <FormLabel>Phone Number</FormLabel>
              <Input
                color="primary"
                type="number"
                name="buyer_phone"
                className="input"
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
              />
              <FormHelperText>
                Please enter the first 10 digits of your mobile number only,
                omitting county code or 0.
              </FormHelperText>
            </FormControl>
            <FormControl id="address" required>
              <FormLabel>Full Address</FormLabel>
              <Input
                type="text"
                className="input"
                name="address"
                onChange={handleChange}
              />
              <FormHelperText>
                Please enter your full address with city and pincode.
              </FormHelperText>
            </FormControl>
          </div>
          <div className="formr">
            <FormControl id="name">
              <FormLabel>Website (if any)</FormLabel>
              <Input
                type="text"
                className="input"
                name="website"
                onChange={handleChange}
              />
              <FormHelperText>
                Please enter your personal or company website url
              </FormHelperText>
            </FormControl>
            <FormControl id="cname" required>
              <FormLabel>{formq.q1}</FormLabel>
              {"dropdown1" in formq && displayDropdown(1)}
              <FormHelperText>{formq.d1}</FormHelperText>
            </FormControl>
            <FormControl id="typeofBiz" required>
              <FormLabel>{formq.q2}</FormLabel>
              {"dropdown2" in formq && displayDropdown(2)}
              <FormHelperText>{formq.d2}</FormHelperText>
            </FormControl>
            <FormControl required>
              <FormLabel>{formq.q3}</FormLabel>
              {"dropdown3" in formq && displayDropdown(3)}
              <FormHelperText>{formq.d3}</FormHelperText>
            </FormControl>
          </div>
          <div className="terms">
            <FormControl required>
              <FormLabel>
                <Checkbox color="primary" required />I agree to Techbud's{" "}
                <a href="https://techbud.in/termsandcondition" target="_blank">
                  Terms of Use
                </a>
              </FormLabel>
            </FormControl>
            <FormControl required>
              <FormLabel>
                <Checkbox color="primary" required />I agree to {formq.Name}'s{" "}
                <a href={formq.term} target="_blank">
                  {" "}
                  Terms and Condition{" "}
                </a>
                .for Users and{" "}
                <a href={formq.priv} target="_blank">
                  {" "}
                  Privacy Policy
                </a>
                .* I also agree to receive communications regarding events, news
                and promotions. I can unsubscribe at any time.
              </FormLabel>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className="submit"
              type="submit"
            >
              {" "}
              Submit{" "}
            </Button>
          </div>
        </form>
      </div>
      <FormFooter />
    </div>
  );
}

export default Form;
