import React, { useState } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "../../axios";
import FileDownload from "js-file-download";

const UploadCSV = (props) => {
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  // const [downloadFile, setDownloadFile] = useState();
  // console.log(downloadFile);

  const handleClick = async (event) => {
    console.log("uhhuhuhuh", typeof props.status);
    const id = localStorage.getItem("user");
    console.log("user", id);
    const data = await axios.post("/download", {
      userId: id,
      status: props.status,
      responseType: "blob",
    });
    console.log("dataa from download", data.data)
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "download.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    console.log("this is data", data);
    // FileDownload(data, "download.csv");
  };
  return (
    <div>
      <Button color="primary" onClick={handleClick}>
        Export to CSV
      </Button>
    </div>
  );
};

export default UploadCSV;
