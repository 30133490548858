import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useLocation } from "react-router-dom";
import "./certi.css";

function PDFCertificate() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const search = useLocation();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setTimeout(() => {
      window.print();
    }, 5000);
  }

  return (
    <div>
      <Document
        file="msme_certi_template.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <center>
          <Page
            style={{ position: "relative" }}
            pageNumber={pageNumber}
            height={720}
          />
        </center>
        <div
          style={{
            position: "relative",
            zIndex: 10,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            top: "-22rem",
            left: "2rem",
            fontSize: "35px",
            fontWeight: "900",
          }}
        >
          {query.get("name")}
        </div>
        <div
          style={{
            position: "relative",
            zIndex: 10,
            left: "12rem",
            top: "-12rem",
            fontSize: "20px",
            fontWeight: "900",
          }}
        >
          {query.get("startdate").slice(0, -14)}
        </div>
        <div
          style={{
            position: "relative",
            zIndex: 10,
            display: "flex",
            left: "45rem",
            top: "-13.5rem",
            fontSize: "20px",
            fontWeight: "900",
          }}
        >
          {query.get("id")}
        </div>
      </Document>
    </div>
  );
}

export default PDFCertificate;
