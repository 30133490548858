import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import axios from "../../axios";
import setAuthToken from "../../components/utils/setAuthToken";
import NFTable from "components/NFTable";
import { Icon, Input } from "@material-ui/core";
import DownloadCSV from "./DownloadCSV";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [q, setQ] = useState("");

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("my_token");
      const id = localStorage.getItem("user");
      setAuthToken(token);
      const req = await axios.get("/pay");
      setData(req.data);
      setLoading(false);
    }

    fetchData();
  }, []);

  function delUser(receiptNumber) {
    console.log(receiptNumber);
  }

  function search(rows) {
    data.sort(function compare(a, b) {
      var dateA = new Date(a.startdate);
      var dateB = new Date(b.startdate);
      return dateB - dateA;
    });
    return rows.filter((row) => row.buyer_name.toLowerCase().indexOf(q) > -1);
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <DownloadCSV status="Pending" />
        <div>
          Search Name: &nbsp;
          <Input
            type="text"
            value={q}
            placeholder="Enter Member's Name"
            onChange={(e) => {
              setQ(e.target.value);
            }}
          />
          <Icon style={{ marginBottom: "-9px" }}>search</Icon>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Inactive Member List</h4>
            <p className={classes.cardCategoryWhite}>
              List of Inactive Member(s)
            </p>
          </CardHeader>
          <CardBody>
            <NFTable datas={search(data)} loading={loading} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
