import React, { useState } from "react";
import Button from "components/CustomButtons/Button.js";
import axios from "../../axios";

const UploadCSV = () => {
  const hiddenFileInput = React.useRef(null);
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const reader = new FileReader();
    const theFile = await fileUploaded;
    reader.readAsText(theFile);
    reader.onload = function (e) {
      let csvData = e.target.result;
      console.log("processed", csvData);
      axios
        .post("./upload", {
          data: csvData,
          userId: localStorage.getItem("user"),
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("here: ", response.data.aresult);
            window.location.reload(false);
          } else {
            console.log(response.status + response.data.message);
          }
        });
    };
  };
  return (
    <div>
      <Button color="primary" onClick={handleClick}>
        Upload Payment Log here
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        accept=".csv,.xlsx,.xls"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default UploadCSV;
